import React, { Component, useEffect, useState } from "react";
import { HeadingTextWrapper } from '../../common/Headings';
import CasinoTabGames, { allIcon, netentIcon } from "./casino-tab-games";
// import CasinoSlider from "./casino-slider";
// import RecommendedGames, { RowFirst } from "./recommended-games";
// import Loading from 'src/pages/loading';
import { useNavigate, useParams } from "react-router-dom";
import Tabs from './casino-tab';
import { useMutation } from "@apollo/client";
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
// import AllGames from "./all-games";
import PlayDemoGames from "./bingo-slotGames";
import styled, { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { motion } from 'framer-motion'
import config from "src/config/config";
import Intl from '../../common/Intl';
import SEOContents from "src/components/SEOContents";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import gameData from "src/static/game-data/slotgames.json";
import SlotGameSEO from "src/components/SEOComponents/slotgameSEO";

import { Helmet } from "react-helmet";

//Provider Icons =========================================
// const pragmaticplayIcon = `${'/static/media/pragmatic_icon.png'}`;
const KAgamingIcon = `${'/static/media/kagamingicon.png'}`;
// const macawicon = `${'/static/media/macawicon.png'}`;
const redTigerIcon = `${'/static/media/redTigerIcon.png'}`;
const mplayicon = `${'/static/media/mplayicon.png'}`;
const moj = `${'/static/media/7mojos.png'}`
const BTGIcon = `${'/static/media/btgicon.png'}`
const NLCIcon = `${'/static/media/nlc-icon.png'}`
// const redTigerIcon = `${'/static/media/redTigerIcon.png'}`
const evoPlayIcon = `${'/static/media/Evoplay_icon_desk.png'}`;

let ALLGAMES = <Intl langKey="GAME@ALL" />;
let NETENT = <Intl langKey="GAME@NETENT" />;
// let BETSOFT = <Intl langKey="GAME@BETSOFT" />;
// let PRAGMATICPLAY = <Intl langKey="GAME@PRAGMATICPLAY" />;
let KA_GAMING = <Intl langKey="GAME@KA-GAMES" />;
// let MACAW = <Intl langKey="GAME@MACAW" />;
// let REDTIGER =  <Intl langKey= "GAME@REDTIGER"/>
let MOJOS = <Intl langKey='GAME@7MOJO' />;
let MPLAY = <Intl langKey="GAME@MPLAY" />;
let BTGGAMES = <Intl langKey="GAME@BTG" />;
let NLC = <Intl langKey="GAME@NLCGAMES" />;
let REDTIGER = <Intl langKey="GAME@REDTIGERGAES" />;
let EVOPLAY = <Intl langKey="GAME@EVOPLAY" />;


// let SPINMATIC = <Intl langKey="GAME@SPINMATIC"/>;

const Language = cookieUtil.get(ECookieName.COOKIE_LANG);
const providerTab = [ALLGAMES, NETENT, MPLAY, MOJOS, KA_GAMING, BTGGAMES, NLC,REDTIGER, EVOPLAY];

const structuredData = (domain)=> {

    const breadcrumbData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
        [
          {
           "@type":"ListItem",
            "item":`${domain}`,
            "name":"Homepage",
            "position":1},
          {
           "@type":"ListItem",
            "item":`${domain}/online-casino-games/`,
            "name":"Play Online Casino Games",
            "position":2},
          {
           "@type":"ListItem",
            "item":`${domain}/online-casino-games/slotGames`,
            "name":"Slotgames 🎰",
            "position":3
          }
        ],
            "name":"Breadcrumbs"
        }

        const faqData = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Which are the popular types of Sportsbook betting on yolo247 ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
              }
            },{
              "@type": "Question",
              "name": "Does Yolo247 have domestic cricket matches too?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Be it international or domestic cricket matches, Yolo247 hosts all kinds of cricket matches."
              }
            }]
          }          

      // Combine both data objects
      const combinedData = [breadcrumbData, faqData];
  
      return JSON.stringify(combinedData);
}

const structuredData2 = (domain,id) => {
    const _label = (id || '').replace(/(^\w)/,(e=> e.toUpperCase()));
    
    const breadcrumbData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
        [
          {
           "@type":"ListItem",
            "item":`${domain}`,
            "name":"Homepage",
            "position":1},
          {
           "@type":"ListItem",
            "item":`${domain}/online-casino-games/`,
            "name":"Play Online Casino Games",
            "position":2},
          {
           "@type":"ListItem",
            "item":`${domain}/online-casino-games/slotGames`,
            "name":"Slotgames 🎰",
            "position":3
          },
          {
            "@type":"ListItem",
             "item":`${domain}/online-casino-games/slotGames/${id}`,
             "name":`${_label} Games`,
             "position":4
          }
        ],
            "name":"Breadcrumbs"
    }

    const faqData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "Which are the popular types of Sportsbook betting on yolo247 ?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
          }
        },{
          "@type": "Question",
          "name": "Does Yolo247 have domestic cricket matches too?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Be it international or domestic cricket matches, Yolo247 hosts all kinds of cricket matches."
          }
        }]
      } 

    // Combine both data objects
    const combinedData = [breadcrumbData, faqData];

    return JSON.stringify(combinedData);
  }

const providerIcons = [
    {
        id: 1,
        name: ALLGAMES,
        icon: allIcon
    },
    {
        id: 2,
        name: NETENT,
        icon: netentIcon
    },
    // {
    //     id: 4,
    //     name: PRAGMATICPLAY,
    //     icon: pragmaticplayIcon
    // },
    {
        id: 3,
        name: MPLAY,
        icon: mplayicon
    },
    {

        id: 4,
        name: MOJOS,
        icon: moj
    },
    {
        id: 5,
        name: KA_GAMING,
        icon: KAgamingIcon
    },
    {
        id: 6,
        name: BTGGAMES,
        icon: BTGIcon
    },
    {
        id: 7,
        name: NLC,
        icon: NLCIcon
    },
    {
        id: 8,
        name: REDTIGER,
        icon: redTigerIcon
    },
    {
        id: 9,
        name: EVOPLAY,
        icon: evoPlayIcon
    },
    // {
    //     id: 12,
    //     name: MACAW,
    //     icon: macawicon
    // }
]

function SlotGames(props: any) {

    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE);
    //Added Hindi Images Also
    //const slotGame = gameData(Language);
    const [slotGame, setSlotGame] = useState(gameData);

    const [filteredData, setfilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const { id } = useParams();

    console.log(id, "ambu")

    useEffect(() => {

        // Now Lazy load game data 
        // import('src/static/game-data/slotgames.json').then(data => data.default).then(data => {
            // setSlotGame(data);
            if (!id) {
                setfilteredData(slotGame)
            }

        // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))

        window.scrollTo(0, 0);
    }, [])


    let history: any = useNavigate();

    const backButton = () => {

        window.location.reload();
        triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });
    }

    const searchData = (val) => {
        setSearch(val)
        if (val !== "") {
            const filteredItems = slotGame.filter((item) => {
                //MS - Modifying Search feature for better result
                let type = item.type && item.type.replace(/[\_\-\s]/gi, '');
                let _val = val && val.replace(/[\_\-\s]/gi, '');
                let slug = item.slug ? item.slug.replace(/[\_\-\s]/gi, '') : '';
                
             // Check if either type or slug matches the search value
             if (type.toLowerCase().includes(_val.toLowerCase()) || slug.toLowerCase().includes(_val.toLowerCase())) {
                return item;
            } else {
                const { imgsrc, ...rest } = item;
                // Fallback: Search through all other fields in the object
                return Object.values(rest).join('').toLowerCase().includes(val.toLowerCase());
            }
                
            })
            setfilteredData(filteredItems)
        }
        else {
            setfilteredData(slotGame)
        }
    }

    const budgetData = () => {

    }

    const { app: { brandId } } = props;
   
    let domainURL = 'https://www.yolo247.co';

    if(brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if(brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if(brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.co';
      } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
      }


    let SEOPAGE = id ? id : "slotGames";
    if(id && id === 'netent') {
        SEOPAGE = 'netent'
    }
    else if(id && id === 'no-limit-city') {
        SEOPAGE = 'nlc'
    }
    else if(id && id === 'pragmatic-play') {
        SEOPAGE = 'pragmatic'
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Helmet>
                <script type="application/ld+json">
                    {
                       id ? structuredData2(domainURL,id) : structuredData(domainURL)
                    }

                </script>
            </Helmet>
             <>
                <SEOContents pageName={SEOPAGE} />
            </>
            
            
            {/* <SlotWrapper style={{width:"100%", height:"84vh"}} theme={props.app.theme} customStyle={props.theme.image} > */}
            <SlotWrapper theme={props.app.theme} customStyle={props.theme.image} >
                <TitleHead theme={props.app.theme} customStyle={props.theme.palette}><Intl langKey="TITLE@SLOTGAME" /> & <Intl langKey="TITLE@BINGO" /></TitleHead>
                {/* <BackButton onClick={backButton}> <img src={LeftArrow}/>
                    Back
                </BackButton> */}

                <TabListSection>
                    <Tabs
                        searchText={search}
                        providerIcons={providerIcons}
                        tabs={providerTab}
                        onSearch={searchData}
                        isTabsHidden={false}
                        budgetClick={budgetData}
                        minWidthInContent={'initial'}
                        render={(index) => {
                            return (
                                <>
                                    {providerTab[index] === ALLGAMES && <PlayDemoGames data={filteredData} />}
                                    {providerTab[index] === NETENT && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'NETENT';
                                    })} />}


                                    {/* {providerTab[index] === PRAGMATICPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'PRAGMATICPLAY';
                                    })} />} */}
                                    {providerTab[index] === KA_GAMING && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'KA_GAMING';
                                    })} />}
                                    {/* {providerTab[index] === MACAW && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'MACAW';
                                    })} />} */}

                                    {/* {providerTab[index] === REDTIGER && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'REDTIGER';
                                    })} />} */}

                                    {providerTab[index] === MOJOS && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === '7MOJOS';
                                    })} />}

                                    {providerTab[index] === MPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'MPLAY';
                                    })} />}

                                    {providerTab[index] === BTGGAMES && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'BTGGAMES';
                                    })} />}

                                    {providerTab[index] === NLC && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'NLC';
                                    })} />}

                                    {providerTab[index] === REDTIGER && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'REDTIGER';
                                    })} />}

                                    {providerTab[index] === EVOPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type === 'EVOPLAY';
                                    })} />}
                                </>
                            );
                        }} />
                </TabListSection>

            </SlotWrapper>
            <SlotGameSEO/>

        </motion.div>
    );

};

export default withTheme(withAppContext(SlotGames));


const SlotWrapper: any = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-top: -5px; */
    // height: 87vh;
    /* background-image: url('${(props: any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}'); */
    background-image: url('${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
    background-position: 100%;
    background-size: cover;
`;

const TabListSection = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;

const TitleHead: any = styled(HeadingTextWrapper)`
    font-size: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    /* color: ${(props: any) => (props.theme === 'Dark' ? '#FFF' : props.customStyle['secondary-background-color-new'])}; */
    color: ${(props: any) => (props.theme === 'Dark' ? '#FFF' : '#F4A322')};
    margin-left: 1em;
    padding-top: 10px;
    
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;

    :after {
        content: "";
        width: 500px;
        height: 2px;
        background: linear-gradient(90deg,#F7B349 0%,rgba(235,160,42,0) 50%);
        position: absolute;
        bottom: 0;
        left: 0;
    }
	
	@media only screen and (max-width: 992px){
		font-size: 30px;
	}	
`;

export const Col1: any = styled.div`
    width: 100%;
    height: 275px;
    display: flex;
    background-image: url(${(props: any) => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
`;
